<template>
    <div class="flights-results-cont">
        <v-snackbar v-model="snackbar" top :timeout="5000" :color="color">
          <v-row>
            {{text}}
            <v-spacer></v-spacer>
            <v-btn icon @click="snackbar = false"><v-icon color="white">mdi-close</v-icon></v-btn>
          </v-row>
        </v-snackbar>
        <v-container fluid>
          <v-row justify="center" align="center" style="height: 60vh" v-if="!flightsStore && loaded">
            <div>
              <v-img max-width="200" class="blink-2" src="../../assets/logo-adam.png"></v-img>
            </div>
          </v-row>
          <v-row v-if="flightsStore && !loaded">
              <v-col cols="12" class="d-block d-md-none mt-2">
                  <v-expansion-panels hover>
                      <v-expansion-panel v-if="flightsStore.length > 1">
                          <v-expansion-panel-header expand-icon="mdi-filter-menu">Filter Results</v-expansion-panel-header>
                          <v-expansion-panel-content>
                              <filters v-if="showMatrix" @changed="filter"/>
                              <p v-else class="text-center">Preparing Filters ...</p>
                          </v-expansion-panel-content>
                      </v-expansion-panel>
                  </v-expansion-panels>
              </v-col>
              <v-col cols="3" xl="2" class="d-none d-md-block" v-if="flightsStore.length > 1">
                <v-card class="pa-1" width="100%">
                  <filters v-if="showMatrix" @changed="filter" />
                  <p v-else class="text-center">Preparing Filters ...</p>
                </v-card>
              </v-col>
              <v-col v-if="flights.length == 0 && !loaded" cols="12" :md="flightsStore.length === 1 ? 12 : 9">
                <p class="body-1 font-weight-bold mx-auto primary--text text-center">Sorry, No flights found .</p>
              </v-col>
              <v-col cols="12" :md="flightsStore.length === 1 ? 12 : 9" :xl="flightsStore.length === 1 ? 12 : 10"  v-if="flights.length > 0">
                  <matrix @choose="filter" v-if="flightsStore.length > 1 && showMatrix"/>
                  <flightCard :flight="flights" />
              </v-col>
          </v-row>
          <p v-if="!flightsStore && !loaded" class="body-1 font-weight-bold primary--text text-center mt-5">Sorry, No flights match your search.</p>
        </v-container>
    </div>
</template>

<script>
import flightCard from '@/components/flights/flightCard.vue'
import filters from '@/components/flights/filters.vue'
import matrix from '@/components/flights/matrix.vue'
import flights from '@/requests/flights.js'

export default {
  title: '-Flight results',
  components: {
    flightCard,
    filters,
    matrix
  },
  data () {
    return {
      flights: [],
      snackbar: false,
      color: '',
      text: '',
      showMatrix: false,
      loaded: true
    }
  },
  computed: {
    flightsStore () {
      if (this.$store.state.flightResults) return this.$store.state.flightResults.flights
      return null
    }
  },
  watch: {
    flightsStore (newVal) {
      this.flights = newVal
    }
  },
  methods: {
    filter (flights) {
      if (flights.length > 0) {
        this.flights = flights
      }
    },
    getFlights (params) {
      this.showMatrix = false
      this.$store.dispatch('removeFlightResults')
      this.loaded = true
      flights.getAllFlights(params).then(res => {
        if (res.data.status) {
          this.flights = res.data.data.flights
          this.$store.dispatch('setFlightResults', res.data.data)
        } else {
          this.snackbar = true
          this.color = 'error'
          this.text = 'Could not find flights!'
        }
      })
        .finally(() => {
          this.loaded = false
          this.showMatrix = true
        })
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.getFlights(this.$route.query.flightQuery)
  }
}
</script>

<style>
    .background .container {
      padding-top: 25px;
    }
    .flights-results-cont {
      background-color: rgb(246, 246, 246);
    }
    .blink-2 {
      -webkit-animation: blink-2 1.5s infinite both;
              animation: blink-2 1.5s infinite both;
    }
    @-webkit-keyframes blink-2 {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.2;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes blink-2 {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.2;
      }
      100% {
        opacity: 1;
      }
    }
</style>
