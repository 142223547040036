<template>
    <div>
        <h5 class="headline">Filter Flights</h5>
        <p class="grey--text">{{flightsCount}} Flights</p>
        <v-divider></v-divider>
        <!-- <h5 class="headline mb-5">Price</h5>
        <v-row justify="space-between" v-if="min < max">
          <v-subheader class="primary--text">${{prices[0]}}</v-subheader>
          <v-subheader class="primary--text">${{prices[1]}}</v-subheader>
        </v-row> -->
        <!-- <v-row justify="space-between" v-else>
            <v-subheader color="primary text-center">${{min}}</v-subheader>
        </v-row> -->
        <!-- <v-range-slider
        v-model="prices"
        :min="min"
        :max="max"
        color="primary"
        hide-details
        class="align-center"
        thumb-label
        @change="filterate()"
        v-if="min < max"
        >
        </v-range-slider>
        <v-divider></v-divider> -->

        <h5 v-if="maxStopsDuration.value - minStopsDuration.value > 0" color="primary" class="headline">Stop Duration</h5>
        <h5 v-if="maxStopsDuration.value - minStopsDuration.value > 0" color="primary" class="caption mt-2 mb-5"><strong>Stop Duration Range: <span class="primary--text">{{convertDecimalToText(stopsDurationRange[0])}}</span> - <span class="secondary--text">{{convertDecimalToText(stopsDurationRange[1])}}</span></strong></h5>
        <v-range-slider
        v-model="stopsDurationRange"
        :min="minStopsDuration.value"
        :max="maxStopsDuration.value"
        step="0.25"
        color="primary"
        hide-details
        class="align-center"
        @change="filterate()"
        v-if="maxStopsDuration.value - minStopsDuration.value > 0" >
        </v-range-slider>
        <v-divider></v-divider>
        <v-checkbox v-if="alternateFlightsExist" v-model="alternateFares" color="primary" @change="filterate()">
          <template v-slot:label>
            <span class="body-1 primary--text">Alternate Dates' Fares Only</span>
          </template>
        </v-checkbox>
        <v-checkbox v-if="mailonlyFaresExist && !$cookies.isKey('userToken')" v-model="mailOnlyFares" color="primary" @change="filterate()">
          <template v-slot:label>
            <span class="body-1 primary--text">Email Only Fare</span>
          </template>
        </v-checkbox>
        <v-checkbox v-if="alternateFlightsExist || mailonlyFaresExist" v-model="exactFares" color="primary" @change="filterate()">
          <template v-slot:label>
            <span class="body-1 primary--text">Requested Date Flights</span>
          </template>
        </v-checkbox>
        <v-divider></v-divider>
        <v-container fluid>
            <h5 class="mb-0 headline">Stops</h5>
            <v-row class="my-0 py-0" no-gutters>
                <v-col cols="4" md="12" v-for="(item, i) in stops" :key="i" class="ma-0 pa-0">
                    <v-checkbox v-model="selectedStops" :value="item" color="primary" @change="filterate()">
                        <template v-slot:label>
                          <p class="body-1 font-weight-medium mb-0">{{item === 0 ? 'Non Stop' : (item === 1 ? 'One Stop' : 'Multi Stop')}}</p>
                        </template>
                    </v-checkbox>
                </v-col>
            </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-container fluid>
            <h5 class="mb-0 headline">Airlines</h5>
            <v-row class="my-0 py-0" no-gutters>
                <v-col cols="4" md="12" v-for="(item, i) in airlines" :key="i" class="ma-0 pa-0">
                    <v-checkbox v-model="airs" :disabled="airlines.length < 2" :value="item.airlineCode" color="primary" @change="filterate()">
                        <template v-slot:label>
                          <v-tooltip color="primary" bottom>
                            <template v-slot:activator="{ on }">
                              <!-- {{item.airlineName.substr(0,5)}}- -->
                              <p v-on="on" class="body-1 font-weight-medium mb-0">{{item.airlineCode}}<small>/${{item.price}}</small></p>
                            </template>
                            <span>{{item.airlineName}}</span>
                          </v-tooltip>
                        </template>
                    </v-checkbox>
                </v-col>
            </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-container fluid v-if="stopAirports.length > 0">
            <h5 class="mb-0 headline">Layover Location</h5>
            <v-row class="my-0 py-0" no-gutters>
                <v-col cols="4" md="12" v-for="(item, i) in stopAirports" :key="i" class="ma-0 pa-0">
                    <v-checkbox v-model="selectedStopAirports" :value="item" color="primary" @change="filterate()">
                        <template v-slot:label>
                          <v-tooltip color="primary" bottom>
                            <template v-slot:activator="{ on }">
                              <p v-on="on" class="body-1 font-weight-medium mb-0">{{item}}</p>
                            </template>
                            <span>{{item}}</span>
                          </v-tooltip>
                        </template>
                    </v-checkbox>
                </v-col>
            </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-container fluid>
            <h5 class="mb-0 headline">Aircraft Model</h5>
            <v-row class="my-0 py-0" no-gutters>
                <v-col cols="4" md="12" v-for="(item, i) in aircrafts" :key="i" class="ma-0 pa-0">
                    <v-checkbox v-model="selectedAircrafts" :disabled="aircrafts.length < 2" :value="item" color="primary" @change="filterate()">
                        <template v-slot:label>
                          <v-tooltip color="primary" bottom>
                            <template v-slot:activator="{ on }">
                              <!-- {{item.airlineName.substr(0,5)}}- -->
                              <p v-on="on" class="body-1 font-weight-medium mb-0">{{item}}</p>
                            </template>
                            <span>{{item}}</span>
                          </v-tooltip>
                        </template>
                    </v-checkbox>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
  props: ['alternateFlightsExist', 'mailonlyFaresExist'],
  data () {
    return {
      prices: [],
      showArrival1: false,
      showArrival2: false,
      departTimes1: [0, 1425],
      arrivalTimes1: [0, 1425],
      departTimes2: [0, 1425],
      arrivalTimes2: [0, 1425],
      min: 0,
      max: 0,
      minDepartTime1: '12:00 AM',
      maxDepartTime1: '11:45 PM',
      minArrivalTime1: '12:00 AM',
      maxArrivalTime1: '11:45 PM',
      minDepartTime2: '12:00 AM',
      maxDepartTime2: '11:45 PM',
      minArrivalTime2: '12:00 AM',
      maxArrivalTime2: '11:45 PM',
      minStopsDuration: '',
      maxStopsDuration: '',
      stopsDurationRange: [],
      airs: [],
      airlines: [],
      alternateFares: true,
      mailOnlyFares: true,
      exactFares: true,
      flights: [],
      aircrafts: [],
      selectedAircrafts: [],
      stops: [],
      selectedStops: [],
      stopAirports: [],
      selectedStopAirports: [],
      flightsCount: 0
    }
  },
  computed: {
    flightResults () {
      return this.$store.state.flightResults
    }
  },
  watch: {
    flightResults (newVal, oldVal) {
      this.min = Math.floor(newVal.minPrice)
      this.max = Math.floor(newVal.maxPrice)
      this.prices = [this.min, this.max]
      this.airlines = newVal.airlines
      this.flights = newVal.flights
      this.airs = []
    }
  },
  methods: {
    convertDecimalToText (num) {
      let hours = 0
      if (Number(String(num).split('.')[0]) > 0) hours = Math.floor(num)
      let minutes = 0
      if ((Number(String(num).split('.')[1]) > 0)) minutes = (Number(String(num).split('.')[1]) * 60) / 100
      return hours + ' h ' + Math.floor(minutes) + ' m '
    },
    timesChanged (type, times) {
      switch (type) {
        case 'depart1':
          this.minDepartTime1 = this.formatTimes(times[0])
          this.maxDepartTime1 = this.formatTimes(times[1])
          break
        case 'arrival1':
          this.minArrivalTime1 = this.formatTimes(times[0])
          this.maxArrivalTime1 = this.formatTimes(times[1])
          break
        case 'depart2':
          this.minDepartTime2 = this.formatTimes(times[0])
          this.maxDepartTime2 = this.formatTimes(times[1])
          break
        case 'arrival2':
          this.minArrivalTime2 = this.formatTimes(times[0])
          this.maxArrivalTime2 = this.formatTimes(times[1])
          break
      }
      this.filterate()
    },
    formatTimes (time) {
      let hours = Math.floor(Number(time) / 60)
      let minutes = time - (hours * 60)
      if (hours.length === 1) hours = '0' + hours
      if (minutes.length === 1) minutes = '0' + minutes
      if (minutes === 0) minutes = '00'
      if (hours >= 12) {
        if (hours === 12) {
          minutes = minutes + ' PM'
        } else {
          hours = hours - 12
          minutes = minutes + ' PM'
        }
      } else {
        minutes = minutes + ' AM'
      }
      if (hours === 0) {
        hours = 12
      }
      return hours + ':' + minutes
    },
    convertTime (time) {
      const suffix = time.split(' ')[1]
      let hour = time.split(' ')[0].split(':')[0]
      const minutes = time.split(' ')[0].split(':')[1]
      if (suffix === 'PM') {
        if (hour !== '12') {
          hour = hour * 1 + 12
        }
      } else if (suffix === 'AM' && hour === '12') {
        hour = '00'
      }
      return hour + ':' + minutes + ':00'
    },
    filterate () {
      if (this.mailOnlyFares === null) this.mailOnlyFares = false
      const flights = []
      this.flights.forEach(flight => {
        const normalFare = this.exactFares ? !flight.withAlternateDate && !flight.mailOnlyFare : flight.withAlternateDate && flight.mailOnlyFare
        if ((this.alternateFares && flight.withAlternateDate) || normalFare || (this.mailOnlyFares && flight.mailOnlyFare)) {
          if (this.alternateFares && this.mailOnlyFares) flights.push(flight)
          else if (!(flight.withAlternateDate && flight.mailOnlyFare)) flights.push(flight)
        }
      })
      const stopsMatched = this.matchStops(flights)
      const airlinesMatched = this.matchAirlines(stopsMatched)
      const aircraftMatched = this.matchAircrafts(airlinesMatched)
      const pricesMatched = this.matchPrices(aircraftMatched)
      const timesMatched = this.matchTimes(pricesMatched)
      const layoverLocationMatched = this.matchLayover(timesMatched)
      const stopsDurationMatched = this.matchStopsDurations(layoverLocationMatched)
      const uniqueFlights = this.removeDuplicates(stopsDurationMatched)
      const sortedFlights = this.sortFlightsOnPrice(uniqueFlights)
      this.flightsCount = sortedFlights.length
      this.$emit('changed', uniqueFlights)
    },
    matchStops (flights) {
      const newFlights = []
      let validFlight
      this.selectedStops.forEach(stop => {
        switch (stop) {
          case 0:
            for (let index = 0; index < flights.length; index++) {
              for (let j = 0; j < flights[index].flightSegments.length; j++) {
                if (flights[index].flightSegments[j].stops === 0) validFlight = true
                else validFlight = false
              }
              if (validFlight) newFlights.push(flights[index])
            }
            break
          case 1:
            for (let index = 0; index < flights.length; index++) {
              for (let j = 0; j < flights[index].flightSegments.length; j++) {
                if (flights[index].flightSegments[j].stops === 1) validFlight = true
                else validFlight = false
              }
              if (validFlight) newFlights.push(flights[index])
            }
            break
          default :
            for (let index = 0; index < flights.length; index++) {
              for (let j = 0; j < flights[index].flightSegments.length; j++) {
                if (flights[index].flightSegments[j].stops > 1) validFlight = true
                else validFlight = false
              }
              if (validFlight) newFlights.push(flights[index])
            }
            break
        }
      })
      return newFlights
    },
    matchPrices (flights) {
      const newFlights = []
      flights.forEach(flight => {
        if (this.prices[1] >= Math.floor(flight.pricingInfo.TotalFare) && Math.floor(flight.pricingInfo.TotalFare) >= this.prices[0]) newFlights.push(flight)
      })
      return newFlights
    },
    matchAirlines (flights) {
      let newFlights = []
      if (this.airs.length > 0) {
        flights.forEach(flight => {
          flight.flightSegments.forEach(trip => {
            trip.Segments.forEach(segment => {
              this.airs.forEach(airline => {
                if (airline === segment.MarketingAirlineCode) newFlights.push(flight)
              })
            })
          })
        })
      } else newFlights = flights
      return newFlights
    },
    matchAircrafts (flights) {
      let newFlights = []
      if (this.selectedAircrafts.length > 0) {
        flights.forEach(flight => {
          flight.flightSegments.forEach(trip => {
            trip.Segments.forEach(segment => {
              this.selectedAircrafts.forEach(aircraft => {
                if (aircraft === segment.aircraft) newFlights.push(flight)
              })
            })
          })
        })
      } else newFlights = flights
      return newFlights
    },
    matchTimes (flights) {
      const timeFiltered = []
      flights.forEach(flight => {
        if (this.$store.state.flightType === 'one') {
          const segments = flight.flightSegments[0].Segments
          if (this.convertTime(this.minDepartTime1) < segments[0].DepartureTime + ':00' && segments[0].DepartureTime + ':00' < this.convertTime(this.maxDepartTime1) && this.convertTime(this.minArrivalTime1) < segments[segments.length - 1].ArrivalTime + ':00' && segments[segments.length - 1].ArrivalTime + ':00' < this.convertTime(this.maxArrivalTime1)) {
            timeFiltered.push(flight)
          }
        } else if (this.$store.state.flightType === 'round') {
          const flightSegments = flight.flightSegments
          if (this.convertTime(this.minDepartTime1) < flightSegments[0].Segments[0].DepartureTime + ':00' && flightSegments[0].Segments[0].DepartureTime + ':00' < this.convertTime(this.maxDepartTime1) && this.convertTime(this.minArrivalTime1) < flightSegments[0].Segments[flightSegments[0].Segments.length - 1].ArrivalTime + ':00' && flightSegments[0].Segments[flightSegments[0].Segments.length - 1].ArrivalTime + ':00' < this.convertTime(this.maxArrivalTime1)) {
            if (this.convertTime(this.minDepartTime2) < flightSegments[1].Segments[0].DepartureTime + ':00' && flightSegments[1].Segments[0].DepartureTime + ':00' < this.convertTime(this.maxDepartTime2) && this.convertTime(this.minArrivalTime2) < flightSegments[1].Segments[flightSegments[1].Segments.length - 1].ArrivalTime + ':00' && flightSegments[1].Segments[flightSegments[1].Segments.length - 1].ArrivalTime + ':00' < this.convertTime(this.maxArrivalTime2)) {
              timeFiltered.push(flight)
            }
          }
        } else {
          timeFiltered.push(flight)
        }
      })
      return timeFiltered
    },
    matchLayover (flights) {
      if (this.stopAirports.length > 0) {
        if (this.selectedStopAirports.length > 0) {
          const newFlights = []
          flights.forEach(flight => {
            flight.flightSegments.forEach(trip => {
              trip.Segments.forEach((segment, i) => {
                this.selectedStopAirports.forEach(airport => {
                  if (i < trip.Segments.length) {
                    if (airport === segment.DestinationLocation) newFlights.push(flight)
                  }
                })
              })
            })
          })
          return newFlights
        } else return flights
      } else return flights
    },
    matchStopsDurations (flights) {
      if (this.stopsDurationRange[1] - this.stopsDurationRange[0] > 0) {
        const newFlights = []
        flights.forEach(flight => {
          const validDurations = []
          flight.flightSegments.forEach(trip => {
            trip.stopsDuration.forEach((duration, i) => {
              if (Number(this.stopsDurationRange[0]) <= Number(duration.value) && Number(duration.value) <= Number(this.stopsDurationRange[1])) validDurations[i] = true
              else validDurations[i] = false
            })
          })
          const valid = validDurations.find(e => e === false)
          if (valid !== false) newFlights.push(flight)
        })
        return newFlights
      } else return flights
    },
    removeDuplicates (flights) {
      const uniqueFlights = flights.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id)
        if (!x) {
          return acc.concat([current])
        } else {
          return acc
        }
      }, [])
      return uniqueFlights
    },
    sortFlightsOnPrice (flights) {
      const newFlights = flights.sort((a, b) => parseFloat(a.pricingInfo.TotalFare) - parseFloat(b.pricingInfo.TotalFare))
      return newFlights
    },
    getUniqueList (type, data) {
      switch (type) {
        case 'airline':
          this.airlines = data.reduce((acc, current) => {
            const x = acc.find(item => item.airlineCode === current.airlineCode)
            if (!x) {
              return acc.concat([current])
            } else {
              return acc
            }
          }, [])
          // this.airlines.map(item => this.airs.push(item.airlineCode))
          break
        case 'aircraft':
          this.aircrafts = data.reduce((acc, current) => {
            const x = acc.find(item => item === current)
            if (!x) {
              return acc.concat([current])
            } else {
              return acc
            }
          }, [])
          // this.aircrafts.map(item => this.selectedAircrafts.push(item))
          break
        case 'stops':
          this.stops = data.reduce((acc, current) => {
            const x = acc.find(item => item === current)
            if (!x) {
              return acc.concat([current])
            } else {
              return acc
            }
          }, [])
          this.stops.map(item => this.selectedStops.push(item))
          break
        case 'stopAirports':
          this.stopAirports = data.reduce((acc, current) => {
            const x = acc.find(item => item === current)
            if (!x) {
              return acc.concat([current])
            } else {
              return acc
            }
          }, [])
          // this.stopAirports.map(item => this.selectedStopAirports.push(item))
          break
      }
    }
  },
  created () {
    this.min = Math.floor(this.$store.state.flightResults.minPrice)
    this.max = Math.floor(this.$store.state.flightResults.maxPrice)
    this.minStopsDuration = this.$store.state.flightResults.minStopsDuration
    this.maxStopsDuration = this.$store.state.flightResults.maxStopsDuration
    this.stopsDurationRange = [this.minStopsDuration.value, this.maxStopsDuration.value]
    this.prices = [this.min, this.max]
    this.getUniqueList('airline', this.$store.state.flightResults.airlines)
    this.getUniqueList('aircraft', this.$store.state.flightResults.aircraftModel)
    this.getUniqueList('stops', this.$store.state.flightResults.stops)
    this.getUniqueList('stopAirports', this.$store.state.flightResults.stopOverAirports)
    this.flights = this.$store.state.flightResults.flights
    this.flightsCount = this.$store.state.flightResults.flights.length
  }
}
</script>

<style>
.v-slider--horizontal .v-slider__track-container {
  height: 4px !important;
}
</style>
