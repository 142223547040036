<template>
    <div>
        <v-progress-linear
        indeterminate
        color="primary"
        height="10"
        top
        :active="loaded"
        ></v-progress-linear>
        <v-snackbar id="snackbar" v-model="snackbar" :color="color" top :timeout="5000">
          <v-row>
            {{ text }}
            <v-spacer></v-spacer>
            <v-btn
              dark
              icon
              @click="snackbar = false"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-snackbar>
        <v-overlay :value="showOverlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-container v-if="!loaded && !flightAvailable">
           <v-row justify='center' class="pt-5 mt-5"><p class="display-1 primary--text">This flight is not available, please search again in few minutes.</p></v-row>
        </v-container>
        <v-container fluid v-if="!loaded && flightAvailable">
            <v-stepper v-if="flight !== null" class="elevation-0 my-5" v-model="el">
              <v-stepper-header class="elevation-0">
                <v-stepper-step
                :complete="el > 1"
                :editable="el === 2"
                :step="1"
                color="primary"
                >
                  Travellers & Review
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                :complete="el > 1"
                :step="2"
                color="primary"
                >
                  Payment
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                :complete="el === 3"
                :step="3"
                color="primary"
                >
                  Confirmation
                </v-stepper-step>

              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content :step="1">
                  <v-row class="px-1">
                    <v-col cols="12" md="8" lg="9">
                        <v-form v-model="valid1" ref="form1">
                          <v-card outlined class="pa-2 mb-5">
                            <p class="body-1 font-weight-bold secondary--text">Contact person details</p>
                            <v-row>
                              <v-col cols="12" sm="4" md="3">
                                <v-text-field
                                outlined
                                v-model="name"
                                :rules="[v => !!v || 'Item is required', v => /^[a-zA-Z\s]*$/.test(v) || 'Name Must be letters only']"
                                label="Full Name"
                                required
                                color="blue"
                                id="passenger-fullname"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4" md="4">
                                <phoneInput :phone="phone.nationalNumber" @update="assignPhone" />
                              </v-col>
                              <v-col cols="12" sm="4" md="5">
                                <v-text-field
                                outlined
                                v-model="email"
                                :rules="emailRules"
                                label="E-mail"
                                required
                                color="blue"
                                id="passenger-email"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card>
                          <v-card v-for="n in passengersNum" :key="n" outlined class="mb-5">
                            <v-row class="pa-2">
                              <v-col cols="12">
                                <p class="body-1 secondary--text">
                                  passenger {{n}} details, {{adultsNum >= n ? `Adult ${infantsNum >= n ? 'With infant in lap' : ''}` : (childrenNum >= ( n - adultsNum) ? 'Child' : (infantsNum >= (n - (adultsNum + childrenNum)) ? 'Infant' : null))}}
                                </p>
                              </v-col>
                              <v-col cols="12" :sm="adultsNum >= n ? 6 : 4" md="6" lg="2">
                                <v-select
                                v-model="passengerGender[n-1]"
                                :items="['Male', 'Female']"
                                label="Gender"
                                outlined
                                prepend-inner-icon="mdi-gender-male-female"
                                required
                                class="pa-0"
                                height="56px"
                                color="blue"
                                id="passenger-gender"
                                >
                                  <template v-slot:selection="{ item }">
                                    <span class="primary--text caption">{{item}}</span>
                                  </template>
                                </v-select>
                              </v-col>
                              <v-col cols="12" sm="6" md="6" lg="2" v-if="adultsNum >= n">
                                <v-combobox
                                v-model="passengerTitle[n-1]"
                                :items="['Mr', 'Mrs', 'Ms', 'Miss']"
                                :search-input.sync="search[n-1]"
                                hide-selected
                                hint="Add title and press enter to append it"
                                label="Title"
                                outlined
                                prepend-inner-icon="mdi-card-account-details"
                                class="pa-0"
                                height="56px"
                                color="blue"
                                id="passenger-title-combobox"
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-combobox>
                              </v-col>
                              <v-col cols="12" :sm="adultsNum >= n ? 8 : 8" md="12" :lg="adultsNum >= n ? 8 : 10">
                                <v-row class="my-0 py-0">
                                  <v-col cols="12" sm="4" class="py-0">
                                    <v-text-field
                                    outlined
                                    prepend-inner-icon="mdi-account"
                                    v-model="passengerFirstName[n-1]"
                                    :rules="nameRules"
                                    label="First name"
                                    required
                                    color="blue"
                                    :id="`passenger-${n}-firstName`"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="4" class="py-0">
                                    <v-text-field
                                    outlined
                                    prepend-inner-icon="mdi-account"
                                    v-model="passengerMiddleName[n-1]"
                                    label="Middle name:Optional"
                                    color="blue"
                                    :id="`passenger-${n}-middleName`"
                                    :rules="middleNameRules"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="4" class="py-0">
                                    <v-text-field
                                    outlined
                                    v-model="passengerLastName[n-1]"
                                    prepend-inner-icon="mdi-account"
                                    :rules="nameRules"
                                    label="Last name"
                                    required
                                    color="blue"
                                    :id="`passenger-${n}-lastName`"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" sm="4" md="6" lg="3">
                                <date-picker
                                v-model="dateOfBirth[n-1]"
                                clearable
                                editable
                                valueType="YYYY-MM-DD"
                                placeholder="Birthday"
                                :default-value="adultsNum >= n ? adultsMaxDate : ((childrenNum >= (n - adultsNum)) ? childrenMinDate :  infantsMinDate)"
                                style="width: 100%; height: 56px;"
                                :disabled-date="adultsNum >= n ? adultsBirthDateRange : ((childrenNum >= (n - adultsNum)) ? childrenBirthDateRange :  infantsBirthDateRange)"
                                required
                                format="M/D/YYYY"
                                hint="MM/DD/YYYY"
                                append-to-body
                                :input-attr="{id: 'birthDateId'+n}"
                                @focus="changeInputColor('birthDateId'+n, '#2196F3')"
                                @blur="changeInputColor('birthDateId'+n, '#9e9e9e')"
                                @input-error="changeInputColor('birthDateId'+n, 'red')"
                                :id="`passenger-${n}-birthday`"
                                >
                                </date-picker>
                                <span class="caption grey--text">Hint: MM/DD/YYYY</span>
                              </v-col>
                              <v-col cols="12" sm="4" md="6" lg="3">
                                <v-text-field
                                v-model="passNum[n-1]"
                                :label="flight.isDomesticFlight ? 'Passport Number:Optional' : 'Passport Number'"
                                prepend-inner-icon="mdi-passport"
                                outlined
                                :hint="flight.isDomesticFlight ? 'Optional Field' : ''"
                                persistent-hint
                                color="blue"
                                :id="`passenger-${n}-passport-num`"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4" md="6" lg="3">
                                <date-picker
                                v-model="passExpireDate[n-1]"
                                clearable
                                editable
                                valueType="YYYY-MM-DD"
                                :placeholder="flight.isDomesticFlight ? 'Passport Expire Date:Optional' : 'Passport Expire Date'"
                                :default-value="passportMinExpireDate"
                                style="width: 100%; height: 56px;"
                                :disabled-date="minExpireDateRange"
                                format="M/D/YYYY"
                                hint="MM/DD/YYYY'"
                                append-to-body
                                :input-attr="{id: 'passportExpiry'+n}"
                                @focus="changeInputColor('passportExpiry'+n, '#2196F3')"
                                @blur="changeInputColor('passportExpiry'+n, '#9e9e9e')"
                                @input-error="changeInputColor('passportExpiry'+n, 'red')"
                                :id="`passenger-${n}-passportExpiry`"
                                >
                                </date-picker>
                                <span class="caption grey--text">{{flight.isDomesticFlight ? 'Hint: Optional Field MM/DD/YYYY' : 'Hint: MM/DD/YYYY}'}}</span>
                              </v-col>
                              <v-col cols="12" sm="4" md="6" lg="3">
                                <!-- :rules="[() => (!!issueCountry[n-1] && !flight.isDomesticFlight) || 'This field is required']" -->
                                  <v-autocomplete
                                  v-model="issueCountry[n-1]"
                                  :items="countries"
                                  item-text="name"
                                  item-value="code"
                                  :placeholder="flight.isDomesticFlight ? 'Issuing Country:Optioanl' : 'Issuing Country'"
                                  :hint="flight.isDomesticFlight ? 'Optional Field' : ''"
                                  persistent-hint
                                  name="issue-country-for-passports"
                                  required
                                  outlined
                                  prepend-inner-icon="mdi-flag"
                                  hide-no-data
                                  color="blue"
                                  autocomplete="off"
                                  :menu-props="{ auto: true, maxWidth: 200, overflowY: true }"
                                  :id="`passenger-${n}-passport-issue-country`"
                                  ></v-autocomplete>
                              </v-col>

                            </v-row>
                            <v-divider></v-divider>
                            <v-card-actions>
                              <v-expansion-panels flat>
                                <v-expansion-panel>
                                  <v-expansion-panel-header class="blue--text">
                                    Frequent Flyer and Optional Requests
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-select
                                        v-model="passengerSeatType[n-1]"
                                        :items="['window seat', 'aisle row', 'exist row', 'extra leg room']"
                                        label="Seat Type"
                                        outlined
                                        prepend-inner-icon="mdi-seat-passenger"
                                        class="pa-0"
                                        height="56px"
                                        color="blue"
                                        id="passenger-seat"
                                        >
                                          <template v-slot:selection="{ item }">
                                            <span class="primary--text caption text-capitalize">{{item}}</span>
                                          </template>
                                        </v-select>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                        v-model="passengerRedress[n-1]"
                                        label="TSA Redress #"
                                        outlined
                                        color="blue"
                                        :id="`passenger-${n}-redress-num`"
                                        >
                                          <template v-slot:append>
                                            <v-tooltip
                                            bottom
                                            color="primary white--text"
                                            >
                                              <template v-slot:activator="{ on }">
                                                <v-icon v-on="on">
                                                  mdi-information-outline
                                                </v-icon>
                                              </template>
                                              This is a unique number
                                              the Department of Homeland Security(DHS)
                                              assigns to a passenger
                                              to promote resolution with
                                              previous watch list alerts.
                                            </v-tooltip>
                                          </template>
                                        </v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                        v-model="passengerKnownNumber[n-1]"
                                        label="Known Traveller Number"
                                        outlined
                                        color="blue"
                                        :id="`passenger-${n}-known-traveller-num`"
                                        >
                                        </v-text-field>
                                      </v-col>
                                    </v-row>
                                    <p>Meals And Special Services</p>
                                    <v-row>
                                      <v-col cols="12" sm="6" v-if="!flight.isDomesticFlight">
                                        <v-select
                                        v-model="passengerMeal[n-1]"
                                        :items="['Inf/Baby Food',
                                        'Bland Meal',
                                        'Child Meal',
                                        'Diabetic Meal',
                                        'Gluten Free',
                                        'High Fiber Meal',
                                        'Hindu',
                                        'Kosher',
                                        'Low Calorie',
                                        'Low Carbohydrate',
                                        'Low Choles./Fat',
                                        'Low Protein',
                                        'Low Sodium',
                                        'Muslim Meal',
                                        'Nonlactose',
                                        'No Salt Meal',
                                        'Oriental',
                                        'Peanut Free Meal',
                                        'Low Purine Meal',
                                        'Raw Vegetarian',
                                        'Seafood Meal',
                                        'Spec Request',
                                        'Vegetarian',
                                        'Veg/Lacto-ovo']"
                                        label="Special Meal"
                                        outlined
                                        ></v-select>
                                      </v-col>
                                      <v-col cols="12" sm="6">
                                        <v-select
                                        v-model="passengerSpecialService[n-1]"
                                        outlined
                                        label="Special Service"
                                        :items="['Blind Passenger',
                                        'Bassinet for Infant',
                                        'Deaf Passenger',
                                        'Stretcher Assistance',
                                        'Wheelchair - Cannot walk up stairs nor to the seat. Must be carried.',
                                        'Wheelchair - Can walk up stairs and to the seat',
                                        'Wheelchair - Cannot walk up stairs but is able to walk to the seat',]"
                                        >
                                        </v-select>
                                      </v-col>
                                    </v-row>
                                    <p>Frequent Flyers</p>
                                    <v-row>
                                      <v-col
                                      v-for="(item, x) in flight.marketingAirlines" :key="x"
                                      cols="12"
                                      sm="6"
                                      lg="4"
                                      >
                                        <v-text-field
                                        v-model="frequentFlyersNumbers[n-1][x].number"
                                        label="Frequent Flyer"
                                        outlined
                                        color="blue"
                                        :id="`passenger-${n}-ff-num`"
                                        >
                                          <template v-slot:prepend-inner>
                                            <v-tooltip
                                            bottom
                                            color="primary white--text"
                                            >
                                              <template v-slot:activator="{ on }">
                                                <img v-on="on" :src="item.airlineLogo" style="max-width: 30px; max-height: 30px;" :alt="item.airlineName">
                                              </template>
                                              {{item.airlineName}}
                                            </v-tooltip>
                                          </template>
                                        </v-text-field>
                                      </v-col>
                                    </v-row>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </v-card-actions>
                          </v-card>
                        </v-form>
                    </v-col>
                    <v-col cols="12" md="4" lg="3">
                      <v-card outlined class="elevation-0 mb-5">
                        <v-card-title class="primary--text font-weight-bold py-0 pt-2">Total Price Of Umrah Package</v-card-title>
                        <!-- <v-card-text class="pa-5">
                          <v-row>
                            <v-col cols="12" v-for="(item, i) in flight.pricingInfo.breakDowns" :key="i" >
                              <v-card outlined class="pa-1">
                                <p class="body-1 secondary--text mb-5">
                                  {{item.passengerQuantity}}
                                  <template v-for="type in types">
                                    <span :key="type.code" v-if="type.code === item.passengerType">{{type.label}}</span>
                                  </template>
                                  (s)
                                </p>
                                <v-row justify="space-between" class="mx-2 my-1">
                                  <p class="body-1 primary--text mb-1">Basic fare</p>
                                  <p class="body-1 primary--text mb-1"><strong>${{item.baseFare}}</strong></p>
                                </v-row>
                                <v-row justify="space-between" class="mx-2 mb-1">
                                  <p class="body-1 primary--text mb-1">Taxes per person</p>
                                  <p class="body-1 primary--text mb-1"><strong>${{item.taxes}}</strong></p>
                                </v-row>
                                <v-row justify="space-between" class="mx-2 mb-1">
                                  <p class="body-1 primary--text mb-1">Price per person</p>
                                  <p class="body-1 primary--text mb-1"><strong>${{parseFloat(item.totalFare).toFixed(2)}}</strong></p>
                                </v-row>
                                <v-row justify="space-between" class="mx-2">
                                  <p class="body-1 primary--text mb-2"><strong>Total</strong></p>
                                  <p class="body-1 primary--text mb-2"><strong>${{parseFloat(item.totalFare).toFixed(2) * item.passengerQuantity}}</strong></p>
                                </v-row>
                                <v-btn text v-if="item.baggage_rules.length > 0" class="mt-5" small color="blue" @click="baggageDialog = true; baggageRules = item.baggage_rules">
                                  <template v-for="type in types">
                                    <span :key="type.code" v-if="type.code === item.passengerType" class="text-truncate">Show {{ type.label }} Baggage Rules</span>
                                  </template>
                                </v-btn>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card-text> -->
                        <v-card-actions class="primary py-5">
                          <p class="headline primary white--text font-weight-bold mb-0 text-right">Grand Total</p>
                          <v-spacer></v-spacer>
                          <p class="headline primary white--text font-weight-bold mb-0 text-right">${{(Number(packageTotalPrice) +  Number(flight.pricingInfo.TotalFare)).toFixed(2)}}</p>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-btn
                  color="primary white--text"
                  @click="nextClicked(1)"
                  class="py-8"
                  :style="$vuetify.breakpoint.lgAndUp ? 'width: 75%;' : ($vuetify.breakpoint.md ? 'width: 66%;' : 'width: 100%;')"
                  >
                    Continue to payment
                  </v-btn>
                </v-stepper-content>
                <v-stepper-content :step="2">
                  <v-row class="px-1">
                    <v-col cols="12" md="8" lg="9">
                      <bookinData type="flights" :items="travellersData" />
                      <v-form v-model="valid2" ref="form2">
                        <address-card @setAddress="setAddress" v-if="paymentType === 'credit'" />
                        <v-card outlined class="pa-5 mt-5">
                          <v-card-title>Payment</v-card-title>
                          <v-row>
                          </v-row>
                          <credit @holder="setCardHolder" @card="setCardData" @expire="setCardExpiry" @cvv="setCVV" />
                        </v-card>
                        <v-row class="my-5" v-if="paymentType === 'credit' && $store.state.mode !== 'ats' && $store.state.mode !== 'atsDev'">
                          <v-btn text :color="termsIsRead ? 'success' : 'error'" large @click="termsDialog = true">
                            <v-checkbox id="booking-terms-checkbox" :color="termsIsRead ? 'success' : 'error'" readonly v-model="termsIsRead"></v-checkbox>
                            {{termsIsRead ? 'You read and agreed on terms & conditions.' : 'Please read and agree on terms & conditions!'}}
                          </v-btn>
                        </v-row>
                      </v-form>
                    </v-col>
                    <v-col cols="12" md="4" lg="3">
                      <v-card outlined class="elevation-0 mb-5">
                        <v-card-title class="primary--text font-weight-bold py-0 pt-2">Pricing summary</v-card-title>
                        <!-- <v-card-text class="pa-5">
                          <v-row>
                            <v-col cols="12" v-for="(item, i) in flight.pricingInfo.breakDowns" :key="i" >
                              <v-card outlined class="pa-1">
                                <p class="body-1 secondary--text mb-5">
                                  {{item.passengerQuantity}}
                                  <template v-for="type in types">
                                    <span :key="type.code" v-if="type.code === item.passengerType">{{type.label}}</span>
                                  </template>
                                  (s)
                                </p>
                                <v-row justify="space-between" class="mx-2 my-1">
                                  <p class="body-1 primary--text mb-1">Basic fare</p>
                                  <p class="body-1 primary--text mb-1"><strong>${{item.baseFare}}</strong></p>
                                </v-row>
                                <v-row justify="space-between" class="mx-2 mb-1">
                                  <p class="body-1 primary--text mb-1">Taxes per person</p>
                                  <p class="body-1 primary--text mb-1"><strong>${{item.taxes}}</strong></p>
                                </v-row>
                                <v-row justify="space-between" class="mx-2 mb-1">
                                  <p class="body-1 primary--text mb-1">Price per person</p>
                                  <p class="body-1 primary--text mb-1"><strong>${{parseFloat(item.totalFare).toFixed(2)}}</strong></p>
                                </v-row>
                                <v-row justify="space-between" class="mx-2">
                                  <p class="body-1 primary--text mb-1"><strong>Total</strong></p>
                                  <p class="body-1 primary--text mb-1"><strong>${{parseFloat(item.totalFare).toFixed(2) * item.passengerQuantity}}</strong></p>
                                </v-row>
                                <v-btn text class="mt-5" small color="blue" @click="baggageDialog = true; baggageRules = item.baggage_rules">
                                  <template v-for="type in types">
                                    <span :key="type.code" v-if="type.code === item.passengerType" class="text-truncate">Show {{ type.label }} Baggage Rules</span>
                                  </template>
                                </v-btn>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card-text> -->
                        <v-card-actions class="primary py-5">
                          <p class="headline primary white--text font-weight-bold mb-0 text-right">Grand Total</p>
                          <v-spacer></v-spacer>
                          <p class="headline primary white--text font-weight-bold mb-0 text-right">${{(Number(packageTotalPrice) +  Number(flight.pricingInfo.TotalFare)).toFixed(2)}}</p>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-btn
                  color="primary white--text"
                  @click="nextClicked(2)"
                  class="py-8"
                  :style="$vuetify.breakpoint.lgAndUp ? 'width: 75%;' : ($vuetify.breakpoint.md ? 'width: 66%;' : 'width: 100%;')"
                  >
                    Book
                  </v-btn>
                </v-stepper-content>
                <v-stepper-content :step="3">
                  <v-container>
                    <div style="text-align: center; background-color: white; padding: 20px 50px; margin: 20px auto; border: 1px solid #e8e8e8;">
                      <h2 class="blue--text">Thanks for using <span id="site-name">{{ applicationTitle }}</span>!</h2>
                      <hr class="my-2">
                      <h4 class="font-weight-bold my-2">Your Booking Reference: <span class="orange--text">{{confirmData.PNR}}</span></h4>
                      <p style="color: red;">
                        We are currently working with the airline to process your booking. We'll send you a confirmation email within the next few hours with your itinerary and receipt once we have everything finalized!
                      </p>
                    </div>
                    <div style="background-color: #ffff8d; padding: 20px 50px; margin: 20px auto;">
                      <p>&#9888; Many countries are restricting entry due to COVID-19. Before you travel, we recommend that you check the latest entry requirements before your trip as they are updated often. You can find more information about these restrictions (by country) on the U.S. Department of State <a href="https://travel.state.gov/content/travel/en/traveladvisories/COVID-19-Country-Specific-Information.html" target="_blank">site</a> , or on the International Air Transport Association (IATA) <a href="https://www.iatatravelcentre.com/international-travel-document-news/1580226297.htm" target="_blank">site</a> .</p>
                    </div>
                    <!-- contact person setails -->
                    <h3 style="margin: 20px auto; color: #3565b0; font-size: 25px;">Contact person Details</h3>
                    <div v-if="confirmData.contact_person_details">
                      <table style="border: 1px solid #e8e8e8; width: 100%; background-color: white; text-align: center; padding: 10px;">
                        <thead>
                          <tr>
                            <th scope="name" style="border-bottom: 1px solid #ccc; padding-bottom: 10px;">Name</th>
                            <th scope="Email" style="border-bottom: 1px solid #ccc; padding-bottom: 10px;">Email</th>
                            <th scope="Phone" style="border-bottom: 1px solid #ccc; padding-bottom: 10px;">Phone</th>
                          </tr>
                        </thead>
                        <tbody>
                          <td style="padding-top: 10px;">{{confirmData.contact_person_details.name}}</td>
                          <td style="padding-top: 10px;">{{this.email}}</td>
                          <td style="padding-top: 10px;">{{confirmData.contact_person_details.phone}}</td>
                        </tbody>
                      </table>
                    </div>
                    <!-- travellers data -->
                    <h3 style="margin: 20px auto; color: #3565b0; font-size: 25px;">Travellers Details</h3>
                    <div>
                      <table style="border: 1px solid #e8e8e8; width: 100%; background-color: white; text-align: center; padding: 10px;">
                        <thead>
                          <tr>
                            <th style="border-bottom: 1px solid #ccc; padding-bottom: 10px;">Passenger</th>
                            <th style="border-bottom: 1px solid #ccc; padding-bottom: 10px;">Passport Number</th>
                            <th style="border-bottom: 1px solid #ccc; padding-bottom: 10px;">Seat Selection</th>
                            <th style="border-bottom: 1px solid #ccc; padding-bottom: 10px;">Country</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="my-3" v-for="(passenger, i) in confirmData.passengers_details" :key="i">
                            <td>{{passenger.passengerTitle ? passenger.passengerTitle : ''}} {{passenger.passengerFirstName}} {{passenger.passengerLastName}}</td>
                            <td>{{passenger.passport_number}}</td>
                            <td>{{passenger.seatSelectType}}</td>
                            <td>{{passenger.passport_issue_country}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- billing section -->
                    <div style="background-color: white; border: 1px solid #e8e8e8; margin-top: 20px">
                      <div style="background-color: #3565b0; color: white; font-size: 20px; padding: 10px;">Billing</div>
                      <table style="width: 100%; padding: 10px;">
                        <!-- <tr>
                          <td style="text-align: left; font-size: 20px; padding: 10px 0; border-bottom: 1px dashed #dedede;">Base</td>
                          <td style="text-align: right; font-size: 20px; padding: 10px 0; border-bottom: 1px dashed #dedede;">${{flight.pricingInfo.baseFare}}</td>
                        </tr>
                        <tr>
                          <td style="text-align: left; font-size: 20px; padding: 10px 0; border-bottom: 1px solid #dedede;">Taxes</td>
                          <td style="text-align: right; font-size: 20px; padding: 10px 0; border-bottom: 1px solid #dedede;">${{flight.pricingInfo.taxes}}</td>
                        </tr> -->
                        <tr>
                          <td style="text-align: left; font-size: 20px; font-weight: bold; padding: 10px 0;">Total Charge</td>
                          <td style="text-align: right; font-size: 20px; font-weight: bold; padding: 10px 0;">${{(Number(packageTotalPrice) + Number(flight.pricingInfo.TotalFare)).toFixed(2)}}</td>
                        </tr>
                      </table>
                    </div>
                    <v-btn
                      color="primary white--text"
                      block
                      @click="$router.push({ path: '/' })"
                      class="my-5"
                    >
                      Done
                    </v-btn>
                  </v-container>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          <v-row v-else>
            <v-col cols="12">
              <p class="display-3 primary--text">This flight is not available, please search again.</p>
            </v-col>
          </v-row>
          <v-dialog scrollable v-model="termsDialog" max-width="700">
            <v-card class="pa-5">
              <v-card-text style="height: 500px;">
                <terms />
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-checkbox color="primary" @change="termsIsRead ? termsDialog = false : ''" v-model="termsIsRead" label="I agree on terms & conditions"></v-checkbox>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog max-width="700" v-model="baggageDialog">
            <v-card>
                <div v-if="baggageRules.length > 0">
                  <div v-for="(item, i) in baggageRules" :key="i">
                    <v-card-title class="headline primary white--text font-weight-regular">
                      <span>
                        {{item.airlineName}}, <sub class="caption">{{item.airlineCode}} </sub>
                        <span>{{i === 0 || $store.state.flightType === 'multi' ? 'Departure' : 'Return'}}</span>
                        Baggage Rules
                      </span>
                    </v-card-title>
                    <a class="ma-3 blue--text" target="_blank" v-if="item.baggage_url" :href="item.baggage_url">Show All Baggage Rules</a>
                    <v-card-text v-if="item.freePieces">
                        <p class="headline text-center secondary--text">Free Pieces</p>
                        <v-simple-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Number Of Pieces
                                    </th>
                                    <th class="text-left">
                                        Weight
                                    </th>
                                    <th class="text-left">
                                        Size
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">{{ item.freePieces.numberOfPieces }}</td>
                                    <td class="text-left">{{ item.freePieces.weight }}</td>
                                    <td class="text-left">{{ item.freePieces.size }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-text v-if="item.additionalCost">
                        <v-divider class="mt-2 mb-5"></v-divider>
                        <p class="headline text-center secondary--text">Additional Cost</p>
                        <v-simple-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Fees
                                    </th>
                                    <th class="text-left">
                                        Weight
                                    </th>
                                    <th class="text-left">
                                        Size
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">$ {{ item.additionalCost.fees }}</td>
                                    <td class="text-left">{{ item.additionalCost.weight }}</td>
                                    <td class="text-left">{{ item.additionalCost.size }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                  </div>
                </div>
            </v-card>
          </v-dialog>
        </v-container>
    </div>
</template>

<script>
import flights from '@/requests/flights.js'
import packages from '@/requests/packages.js'
import { countries } from '@/countries.js'
import bookinData from '@/components/booking/bookingDataTable'
import credit from '@/components/booking/credit'
import phoneInput from '@/components/booking/mobile'
import terms from '@/components/booking/terms'
import addressCard from '@/components/booking/addressInfo'
import { mapState } from 'vuex'

export default {
  components: {
    bookinData,
    credit,
    phoneInput,
    terms,
    addressCard
  },
  data () {
    return {
      applicationTitle: 'Adam travel Umrah',
      el: 1,
      showOverlay: false,
      paymentType: 'credit',
      nextLabel: 'Continue',
      valid1: true,
      valid2: true,
      loaded: true,
      snackbar: false,
      color: '',
      text: '',
      // flight: null,
      baggageRules: [],
      passengers: {},
      name: '',
      nameRules: [v => (!!v && v.length > 1) || 'Item is required at least 2 characters',
        v => /^[A-Za-z][A-Za-z]*$/.test(v) || 'Name Must be letters only with no spaces'
      ],
      middleNameRules: [
        v => (/^[A-Za-z][A-Za-z]*$/.test(v) || !v) || 'Name Must be letters only with no spaces'
      ],
      phone: '',
      phoneRules: [
        v => !!v || 'Phone is required',
        v => (!isNaN(v)) || 'Phone must be only numbers'
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'E-mail must be valid'
      ],
      zipRules: [
        v => !!v || 'Zip code is required',
        v => (!isNaN(v)) || 'Zip code must be only numbers'
      ],
      zip: '',
      requiredRule: [
        v => !!v || 'Field is required'
      ],
      passRules: [
        v => !!v || 'Passport number is required',
        v => v.length <= 15 || 'Maximum number of characters is 15'
      ],
      address: '',
      streetLine: '',
      cardExpireDate: '',
      cardNum: '',
      cvv: '',
      cardCode: '',
      cardHolder: '',
      types: [
        { label: 'Adult', code: 'ADT' },
        { label: 'Child', code: 'CNN' },
        { label: 'Infant', code: 'INF' }
      ],
      baggageDialog: false,
      passengerType: [],
      passengerTitle: [],
      passengerGender: [],
      passengerSeatType: [],
      passengerRedress: [],
      passengerKnownNumber: [],
      passengerMeal: [],
      passengerSpecialService: [],
      search: [],
      passengerFirstName: [],
      passengerMiddleName: [],
      passengerLastName: [],
      dateOfBirth: [],
      passExpireDate: [],
      passExpireDateText: [],
      frequentFlyersNumbers: [],
      minExpireDate: '',
      passNum: [''],
      issueCountry: [],
      totalPrice: '',
      currentPage: 0,
      travellersData: [],
      termsIsRead: false,
      termsDialog: false,
      lastFlightSegmentDate: '',
      passportExpiry: null,
      confirmData: {},
      flightAvailable: false,
      packageTotalPrice: 0
    }
  },
  watch: {
    el (newVal) {
      if (newVal === 2) {
        document.getElementById('card-address').addEventListener('input', (e) => {
          this.$store.dispatch('setWrittenAddress', e.target.value)
        })
      }
    }
  },
  computed: {
    ...mapState(['flight', 'packageDetails', 'adults', 'oldChildren', 'children', 'infants']),
    countries () {
      return countries
    },
    adultsNum () {
      return Number(this.passengers.adults)
    },
    childrenNum () {
      return Number(this.passengers.children)
    },
    infantsNum () {
      return Number(this.passengers.infants)
    },
    passengersNum () {
      return Number(this.passengers.adults) + Number(this.passengers.children) + Number(this.passengers.infants)
    },
    adultsMaxDate () {
      const today = new Date(this.lastFlightSegmentDate)
      const year = today.getFullYear()
      today.setDate(today.getDate())
      const max = today.setFullYear(year - 12)
      return max
    },
    childrenMaxDate () {
      const today = new Date(this.lastFlightSegmentDate)
      const year = today.getFullYear()
      today.setDate(today.getDate())
      const max = today.setFullYear(year - 2)
      return max
    },
    childrenMinDate () {
      const today = new Date(this.lastFlightSegmentDate)
      const year = today.getFullYear()
      today.setDate(today.getDate())
      const min = today.setFullYear(year - 12)
      return min
    },
    infantsMinDate () {
      const today = new Date(this.lastFlightSegmentDate)
      const year = today.getFullYear()
      today.setDate(today.getDate())
      const min = today.setFullYear(year - 2)
      return min
    },
    passportMinExpireDate () {
      const lastFlightSegment = this.flight.flightSegments[this.flight.flightSegments.length - 1]
      const lastArrivalDate = lastFlightSegment.leg.arrivalDate
      const today = new Date(lastArrivalDate)
      today.setMonth(today.getMonth() + 6)
      return today
    }
  },
  methods: {
    setAddress (address, zip, streetLine) {
      this.address = address
      this.zip = zip
      this.streetLine = streetLine
    },
    changeInputColor (id, color) {
      document.getElementById(id).style.borderColor = color
    },
    assignPhone (phone) {
      this.phone = phone
    },
    adultsBirthDateRange (date) {
      return date > new Date(this.adultsMaxDate) || date < new Date('1900-1-1')
    },
    childrenBirthDateRange (date) {
      return date > new Date(this.childrenMaxDate) || date < new Date(this.childrenMinDate)
    },
    infantsBirthDateRange (date) {
      const today = new Date()
      return date > today || date < new Date(this.infantsMinDate)
    },
    minExpireDateRange (date) {
      return date < new Date(this.passportMinExpireDate)
    },
    setCardData (number, type) {
      this.cardNum = number
      switch (type) {
        case 'visa':
          this.cardCode = 'VI'
          break
        case 'mastercard':
          this.cardCode = 'CA'
          break
        case 'american-express':
          this.cardCode = 'AX'
          break
        case 'discover':
          this.cardCode = 'DS'
          break
        case 'diners-club':
          this.cardCode = 'DN'
          break
        default:
          this.cardCode = type
          break
      }
    },
    setCardExpiry (date) {
      this.cardExpireDate = date
    },
    setCVV (cvv) {
      this.cvv = cvv
    },
    setCardHolder (name) {
      this.cardHolder = name
    },
    classType () {
      let type
      switch (this.passengers.classType) {
        case 'Y':
          type = 'Economy'
          break
        case 'C':
          type = 'Business'
          break
        case 'F':
          type = 'First'
          break
        case 'S':
          type = 'Premium Economy'
          break
        default:
          break
      }
      return type
    },
    book () {
      if (this.termsIsRead) {
        this.showOverlay = true
        const body = {
          flight_id: this.flight.id,
          search_id: this.search_id,
          contact_person_name: this.name,
          contact_phone: this.phone.nationalNumber,
          countryIsoCode: this.phone.countryCode,
          contact_email: 'support@adamtravel.com',
          zipCode: this.zip,
          address: this.address,
          streetLine: this.streetLine,
          creditCardNumber: this.cardNum,
          creditCardExpireDate: this.cardExpireDate,
          creditCardType: this.cardCode,
          cvv: this.cvv,
          cardHolderName: this.cardHolder,
          passengerDetails: this.travellersData
        }
        flights.createPNR(body).then(res => {
          if (res.data.status) {
            this.confirmData = res.data.data
            this.totalPrice = res.data.data.totalPrice
            // window.scrollTo(0, 0)
            // this.currentPage = 3
            // this.el = 3
            const payload = {
              flightDetails: this.flight,
              packageDetails: this.$store.state.packageDetails,
              passengerDetails: this.travellersData,
              contactEmail: this.email,
              occupancy: this.adults + this.oldChildren,
              total: this.packageTotalPrice + this.flight.pricingInfo.TotalFare,
              pnr: this.confirmData.PNR
            }
            packages.savePackage(payload).then(res => {
              if (res.data.status) {
                window.scrollTo(0, 0)
                this.currentPage = 3
                this.el = 3
              } else {
                this.snackbar = true
                this.goNext = false
                this.color = 'error'
                this.text = res.data.message
              }
            })
              .finally(() => {
                this.showOverlay = false
              })
          } else {
            this.snackbar = true
            this.goNext = false
            this.color = 'error'
            this.showOverlay = false
            this.text = res.data.message
          }
        })
      } else {
        this.snackbar = true
        this.color = 'warning'
        this.text = 'Please read and agree on our terms and conditions!'
      }
    },
    prepareTravellersData () {
      this.travellersData = []
      for (let index = 0; index < this.passengersNum; index++) {
        let type
        let title
        let gender
        if (this.passengerGender[index]) {
          this.passengerGender[index] === 'Male' ? gender = 'M' : gender = 'F'
        }
        if (this.adultsNum >= (index + 1)) {
          type = 'ADT'
          title = this.passengerTitle[index]
          if (this.passengerGender[index] === undefined) {
            if (title === 'Mr') {
              this.passengerGender[index] = 'M'
              gender = 'M'
            } else if (['Mrs', 'Ms', 'Miss'].indexOf(title) !== -1) {
              this.passengerGender[index] = 'F'
              gender = 'F'
            }
          }
        } else if (this.childrenNum >= ((index + 1) - this.adultsNum)) {
          type = 'CNN'
          title = 'CHD'
        } else if (this.infantsNum >= ((index + 1) - (this.adultsNum + this.childrenNum))) {
          type = 'INF'
          title = 'INF'
        }
        const frequentFlyer = []
        this.frequentFlyersNumbers[index].forEach(element => {
          if (element.number) frequentFlyer.push(element)
        })
        this.travellersData.push({
          passengerType: type,
          passengerTitle: title,
          passengerGender: gender,
          passengerFirstName: this.passengerFirstName[index],
          passengerMiddleName: this.passengerMiddleName[index],
          passengerLastName: this.passengerLastName[index],
          date_of_birth: this.getBirthdate(this.dateOfBirth[index]),
          passport_number: this.passNum[index] ? this.passNum[index] : '',
          passport_expire_date: this.passExpireDate[index] ? this.passExpireDate[index] : '',
          passport_issue_country: this.issueCountry[index] ? this.issueCountry[index] : '',
          seatSelectType: this.passengerSeatType[index] ? this.passengerSeatType[index] : 'not selected',
          tsaRedress: this.passengerRedress[index],
          frequentFlyer: frequentFlyer,
          passengerMeal: this.passengerMeal[index] ? this.passengerMeal[index] : '',
          passengerSpecialService: this.passengerSpecialService[index] ? this.passengerSpecialService[index] : '',
          passengerKnownNumber: this.passengerKnownNumber[index] ? this.passengerKnownNumber[index] : ''
        })
      }
    },
    getBirthdate (date) {
      if (date) {
        let newDate = ''
        const segments = date.replace(/\//g, '-').split('-')
        if (segments[0].length === 1) segments[0] = `0${segments[0]}`
        if (segments[1].length === 1) segments[1] = `0${segments[1]}`
        newDate = `${segments[0]}-${segments[1]}-${segments[2]}`
        return newDate
      }
    },
    nextClicked (currentPage) {
      try {
        if (currentPage === 1) {
          this.currentPage = 1
          window.scrollTo(0, 0)
          this.$refs.form1.validate()
          if (this.valid1) {
            const fullNames = []
            for (let index = 0; index < this.passengerFirstName.length; index++) {
              fullNames.push(`${this.passengerFirstName[index]} ${this.passengerLastName[index]}`)
            }
            if (new Set(fullNames).size === fullNames.length) {
              if (new Set(this.passNum).size === this.passNum.length || this.flight.isDomesticFlight) {
                if (this.phone.valid) {
                  let passportExpireDateExists = true
                  for (let index = 0; index < this.passengerFirstName.length; index++) {
                    if (!this.passExpireDate[index]) passportExpireDateExists = false
                  }
                  if (passportExpireDateExists || this.flight.isDomesticFlight) {
                    let passportIssueCountryExists = true
                    for (let index = 0; index < this.passengerFirstName.length; index++) {
                      if (!this.issueCountry[index]) passportIssueCountryExists = false
                    }
                    if (passportIssueCountryExists || this.flight.isDomesticFlight) {
                      let birthDaysExists = true
                      for (let index = 0; index < this.passengerFirstName.length; index++) {
                        if (!this.dateOfBirth[index]) {
                          document.getElementsByClassName('mx-input').forEach((element, i) => {
                            if (!element.value) {
                              this.changeInputColor('birthDateId' + (i + 1), 'red')
                            }
                          })
                          birthDaysExists = false
                        }
                      }
                      if (birthDaysExists) {
                        this.snackbar = false
                        this.prepareTravellersData()
                        this.nextLabel = 'Book'
                        this.el = 2
                        window.scrollTo(0, 0)
                      } else {
                        this.snackbar = true
                        this.color = 'error'
                        this.text = 'Please make sure to enter pasengers birth dates.'
                      }
                    } else {
                      this.snackbar = true
                      this.color = 'error'
                      this.text = 'Please make sure to enter passports issue country.'
                    }
                  } else {
                    this.snackbar = true
                    this.color = 'error'
                    this.text = 'Please make sure to enter passports expiry dates.'
                  }
                } else {
                  this.snackbar = true
                  this.color = 'error'
                  this.text = 'Please provide a valid phone number'
                }
              } else {
                this.snackbar = true
                this.color = 'warning'
                this.text = 'Passports numbers must be unique'
              }
            } else {
              this.snackbar = true
              this.color = 'warning'
              this.text = 'Please avoid using similar names for passengers'
            }
          } else {
            this.snackbar = true
            this.color = 'warning'
            this.text = 'Please fill all fields to continue'
          }
        } else if (currentPage === 2) {
          this.currentPage = 2
          this.$refs.form2.validate()
          if (this.valid2) {
            if ((this.cardExpireDate && this.paymentType === 'credit') || this.paymentType !== 'credit') {
              if (!this.address && this.$store.state.writtenAddress) this.address = this.$store.state.writtenAddress
              if (!this.zip && this.$store.state.zipCode) this.zip = this.$store.state.zipCode
              if (this.address) {
                if (this.zip) {
                  this.book()
                } else {
                  this.snackbar = true
                  this.color = 'error'
                  this.text = 'Please Enter your ZIP code'
                }
              } else {
                this.snackbar = true
                this.color = 'error'
                this.text = 'Please Enter your address'
                document.getElementsByClassName('address-autocomplete')[0].style.borderColor = 'red'
              }
            } else {
              this.snackbar = true
              this.color = 'error'
              this.text = 'Please Enter credit card expire date'
              document.getElementsByClassName('mx-auto')[0].style.borderColor = 'red'
            }
          } else {
            this.snackbar = true
            this.color = 'warning'
            this.text = 'Please fill all fields to Book'
          }
        } else if (currentPage === 3) {
          this.currentPage = 3
          window.scrollTo(0, 0)
          this.$router.push({ name: 'Home' })
        }
      } catch (error) {}
    },
    backClicked (currentPage) {
      this.currentPage = currentPage
      if (currentPage === 3) {
        return false
      } else if (currentPage === 2) {
        window.scrollTo(0, 0)
        this.nextLabel = 'Continue'
        return true
      } else return true
    },
    getCityNights (city) {
      return this.packageDetails.cities.find(item => item.cityName === city).cityNightNumber
    }
  },
  created () {
    window.scrollTo(0, 0)
    if (!this.$store.state.flight) {
      this.$router.go(-1)
    } else {
      this.search_id = this.$store.state.flight.search_id
      const params = new URLSearchParams()
      params.append('flight_id', this.$store.state.flight.id)
      params.append('search_id', this.search_id)
      this.loaded = true
      flights.getFlight(params).then(res => {
        if (res.data.status) {
          this.$store.dispatch('setFlight', res.data.data)
          const body = {
            adultNumber: this.adults + this.oldChildren,
            childNumber: this.children + this.children,
            nightsNumber_Medina: this.getCityNights('Medina'),
            nightsNumber_Mecca: this.getCityNights('Makkah')
          }
          packages.checkPackagePrice(body, this.packageDetails.id).then(res => {
            if (res.status === 200) {
              this.packageTotalPrice = res.data.data.total
            } else {
              this.snackbar = true
              this.color = 'error'
              this.text = 'Could not find flight!'
            }
          }).finally(() => {
            this.loaded = false
          })
          this.flightAvailable = true
        } else {
          this.loaded = false
          this.snackbar = true
          this.color = 'error'
          this.text = 'Could not find flight!'
        }
      })
      // this.flight = this.$store.state.flight
      const passengersNum = Number(this.$store.state.adults) + Number(this.$store.state.children) + Number(this.$store.state.oldChildren) + Number(this.$store.state.infants)
      for (let i = 0; i < passengersNum; i++) {
        this.passNum.push('')
        this.passExpireDate.push(null)
        this.frequentFlyersNumbers.push([])
      }
      this.lastFlightSegmentDate = this.flight.flightSegments[this.flight.flightSegments.length - 1].leg.arrivalDate
      this.frequentFlyersNumbers.forEach(list => {
        this.flight.marketingAirlines.forEach(item => {
          list.push({ airlineCode: item.airlineCode, number: null })
        })
      })
      this.passengers = {
        adults: this.$store.state.adults,
        children: this.$store.state.children + this.$store.state.oldChildren,
        infants: this.$store.state.infants,
        classType: this.$store.state.classType
      }
    }
  }
}
</script>

<style>
  [v-cloak] { display: none; }
  .remove-controls input[type='number'] {
    -moz-appearance:textfield;
  }
  .remove-controls input::-webkit-outer-spin-button,
  .remove-controls input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
  .mx-input {
    width: 100%;
    height: 56px !important;
    border-color: #9e9e9e;
  }
  .cell {
    padding: 1px;
    text-align: center;
    border-left: 1px solid #eee !important;
    border-right: 1px solid #eee !important;
    border-bottom: 0.1px dashed #eee;
  }
</style>
